import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import Ad from '@ifeng-fe/ui_pc_ad';
import Slider from './slider';
import BrandsTitle from './brandsTitle';
import Brands from './brands';
import BrandsLeague from './brandsLeague';
import HotTopic from './hotTopic';
import { NewsStream } from '@ifeng-fe/news-stream';
import '@ifeng-fe/news-stream/lib/NewsStream/style/news-stream-global.css';

class Content extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
        contentAd: PropTypes.object,
    };

    adArr() {
        const { feedAd } = this.props.contentAd;

        const ad = [feedAd];

        return ad;
    }

    render() {
        const {
            slideData,
            recommend,
            moreRecommend,
            brandsRec,
            brandsRecNav,
            fengRec,
            fengVideos,
            newsRec,
            newsVideos, // 时政抓取
            societyRec,
            societyVideos, // 社会抓取
            entertainmentRec,
            entVideos,
            militaryRec,
            militaryVideos, // 军事抓取
            humanityRec,
            artAndCultureVideos, // 艺术文化抓取
            techRec,
            techVideos, // 科技抓取
            brandsLeague,
            hotTopics,
            feed,
            slideSwitch
        } = this.props.content;

        const recommendData = {
            recommend,
            moreRecommend,
        };
        const {
            banner01Ad,
            banner02Ad,
            banner03Ad,
            rectangle01Ad,
            rectangle02Ad,
            rectangle03Ad,
            slideAd,
            streamSoft01Ad,
            streamSoft02Ad,
            streamSoft03Ad,
            streamSoft04Ad,
            streamSoft05Ad,
            streamSoft06Ad,
            streamSoft07Ad,
            brandsRecAd,
            feedAd,
        } = this.props.contentAd;

        const newsStreamProps = {
            dataTypeName: 'selectedPool',
            content: feed,
            columnId: 305534,
            isEnd: false,
            isDisplaySource: false,
            repeatedID: [],
            ad: this.adArr(),
        };

        // if (newsStream) newsStreamProps.dataTypeName = newsStream.type;

        return (
            <div className={styles.content}>
                {/* 轮播图 */}
                {slideData && slideData.length ? (
                    <Chip
                        id="75167"
                        type="recommend"
                        title="视频首页-焦点图"
                        groupName="正文"
                        translate="handleVideoSlideData"
                        content={slideData}>
                        <Slider ad={slideAd} slideSwitch={slideSwitch} />
                    </Chip>
                ) : null}
                <div className={`${styles.top_content} clearfix`}>
                    <div className={styles.leftPart}>
                        <BrandsTitle content={brandsRecNav} />
                        {/* 品牌精选 */}
                        <Chip
                            id="75168"
                            type="recommend"
                            title="视频首页-品牌精选"
                            groupName="正文"
                            content={brandsRec}>
                            <Brands adData={brandsRecAd} />
                        </Chip>
                        {/* 信息流 */}
                        <div className={styles.feedBox}>
                            <NewsStream {...newsStreamProps} />
                        </div>
                        {/* <TopicTech content={techRec} stream={techVideos} ad={streamSoft04Ad} /> */}
                    </div>
                    <div className={styles.rightPart}>
                        <BrandsLeague content={brandsLeague} />
                        <div className={styles.rectangle01Ad}>
                            <Ad content={rectangle01Ad} />
                        </div>
                        <HotTopic content={hotTopics} />

                        <div className={styles.rectangle02Ad}>
                            <Ad content={rectangle02Ad} />
                        </div>
                        <div className={styles.rectangle03Ad}>
                            <Ad content={rectangle03Ad} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Content;
